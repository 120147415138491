import React, { useState } from 'react'
import namedColors from 'color-name-list';
import ColorDisplay from './ColorDisplay'
import vigenere from 'vigeneren'
function Challenge() {
    const nameToColor = (name) => {
        return namedColors.find(color => color.name.toLowerCase().replace(/ /g,'') === name.toLowerCase().replace(/ /g,''));
    }
    const createLink = (e) => {
        navigator.clipboard.writeText("https://colordle.ryantanen.com/?c=" + vigenere.encode(colorInput, "q2wedrfghjklkjnb"))
    }
    const [colorInput, setColorInput] = useState("")
    const [validColor, setValidColor] = useState("")
    const [isValid, setIsValid] = useState(false)
    const onInputChange = (e) => { 
        const input = e.target.value;
        setColorInput(input);
        if(input.length<3) {
          setIsValid(false)
          return;
        }
        console.log(input)
        const foundColor = nameToColor(input);
        if(foundColor) {
          setIsValid(true)
          setValidColor(foundColor);
        } else setIsValid(false)
      }
  return (
    <div>
        
        <input type='text'
         placeholder='Enter your color here'
         autoFocus 
         value={colorInput} 
         onChange={(e)=>onInputChange(e)}
         className='color-input'
        //  ref={}
         ></input>
         <p></p>
         <div className='' style={{
            background: isValid ? validColor.hex : "transparent",
            height: '3em',
            width: '100%',
            border: '3px white solid'
         }}></div>
         <p></p>
        <button style={{border: "3px solid white", padding: "10px"}} onClick={(e)=>createLink(e)}className={`${isValid ? '' : 'disabled'}`} 
           >Copy Link</button>
        
    </div>

  )
}

export default Challenge