import React, { useState } from 'react'
import { MdIosShare, MdShare } from 'react-icons/md'

function ShareScore({gameState, dayNum}) {
    const getShareData = () => {
        return `Colordle ${dayNum}: \n I got the color in ${gameState.selectedColors[gameState.selectedColors.length-1].number || 0}! \n https://colordle.ryantanen.com`
    }
    
    const [copied, setCopied] = useState(false);
    const triggerShare = () => {
           navigator.clipboard.writeText(getShareData());
           setCopied(true);
    }
  return (
    <div>
        <button className='bp' onClick={()=>triggerShare()}> Share <MdShare /></button>
        <div style={{
            display: copied ? 'inline' : 'none'
        }}>{'Copied to clipboard!'}</div>
        </div>
  )
}

export default ShareScore