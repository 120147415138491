import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineCheck, AiOutlineInfoCircle, AiOutlineSwap } from 'react-icons/ai';

import { FaUserFriends} from 'react-icons/fa';
import { MdOutlineLeaderboard } from 'react-icons/md';
import './infobar.css';
import UniqueColorGraph from './UniqueColorGraph';
import Challenge from './Challenge';
function InfoBar({gameState, nameToColor, hexToRgb, colorDiff, lastColor}) {
    const [displayed, setDisplayed] = useState(0);
    const [toggleLColor, setToggleLColor] = useState(false)
    useEffect(() => {
        // if(!localStorage.getItem('colordle-state')) setDisplayed(1);
    }, [])

  return (
    <div className='info-bar' style={{textAlign: 'right'}}>
        
        <button onClick={()=>(setDisplayed(displayed === 0 ? 4 : 0))}>
            
        <FaUserFriends style={{fontSize: '2.3em', marginRight: '0.5rem'}} />
        </button>
        <button onClick={()=>setDisplayed(displayed === 0 ? 1 : 0)}>
        <AiOutlineInfoCircle style={{fontSize: '2.3em', marginRight: '0.5rem'}} />
        </button>
        <button onClick={()=>setDisplayed(displayed === 0 ? 2: 0)}>
        <MdOutlineLeaderboard style={{fontSize: '2.3em', marginRight: '0.5rem'}} />
        </button>
            <div className='floating-panel' style={{
                display: displayed === 1 ? 'inline' : 'none'}}>
            
            <h2>Welcome to Colordle, made by Ryan Tanenholz! <br></br></h2>
            <h3>Contact: rjtanenholz@gmail.com</h3>
            Colordle is based on the original <a href='https://www.nytimes.com/games/wordle/index.html'> Wordle</a>{' '} 
            by Josh Wardle and related games!
                <p></p>
            Come back each day for a new hidden color! <p></p>
            {!toggleLColor && <button style={{background: 'white', color: 'black', padding: '10px'}} onClick={()=>setToggleLColor(true)}>Reveal Yesterday's Color</button>}
            {toggleLColor && "Yesterday's Colordle: " + nameToColor(lastColor).name}
            <p></p>
            HOW TO PLAY: <mark>The higher the percent, the closer your guess is to the hidden color!</mark>
            <p></p>
            Use your guesses to get more information about the color. It can take a while, don't give up!
            <p></p>
            This game would not be possible without the amazing color names from <a href='https://github.com/meodai/color-names'>this github repo!</a> 
            <p></p>
            Having trouble? Here is a list of useable colors: <a href='https://codepen.io/meodai/full/VMpNdQ/'>Click</a>
            <p></p>
            Colors have reset a few times, as they have ran out.
            <p>
            Thursday March 24th 2022 - Oops! I just found out that Hank Green had come up with Colordle 5 days ago, I had not seen his tweet before I swear!
            <br />
            <a href='https://twitter.com/hankgreen/status/1505261723716145156'>Hank Greens Tweet</a>
            </p>
            <p>
                <button style={{
                    background: 'white',
                    color: 'black',
                    padding: '0.5em 1em'
                }}onClick={()=>setDisplayed(0)}>Close</button>
            </p>
            </div>
            <div className='floating-panel' style={{
                display: displayed === 2 || displayed === 3 ? 'inline' : 'none'}}>
            
                <h1>Win Streak: {gameState.streak || '0'}</h1>
                {gameState.uniqueColorsGuessed && (<h1>Unique Colors Guessed: { gameState.uniqueColorsGuessed.length || '0'}</h1>)}
                <center style={{
                display: displayed === 3 ? 'inline' : 'none'}} >{displayed === 3 && (<UniqueColorGraph gameState={gameState} hexToRgb={hexToRgb} colorDiff={colorDiff}  nameToColor={nameToColor}/>)} </center>
            </div>
            <div className='floating-panel' style={{
                display: displayed === 4 ? 'inline' : 'none'}}>
                <p>
                    <h1>Challenge your friends</h1>
                    
                </p>
                    <h2>Pick a color</h2>
                    
                
                    <Challenge />
                <p />
                <button style={{
                    background: 'white',
                    color: 'black',
                    padding: '0.5em 1em'
                }}onClick={()=>setDisplayed(0)}>Close</button>
            </div>
        </div>

  )
}

export default InfoBar