import React, { useEffect, useState } from 'react'

function UniqueColorGraph({gameState, nameToColor, colorDiff, hexToRgb}) {
   const colorDiff2 = (c1, c2) => { return  Math.sqrt((c1.r - c2.r) * (c1.r - c2.r) 
                               + (c1.g - c2.g) * (c1.g - c2.g)
                               + (c1.b - c2.b) * (c1.b - c2.b)); }

    const [hexColor, setHexColors] = useState(undefined);
    const white = hexToRgb(nameToColor('white').hex);
    const genDataFromSave = () => {
        let sColors = gameState.uniqueColorsGuessed;
        let tmp = [];
        let tmp2 = [];
        let tmp3 = [];
        sColors.map(element => {
          (tmp.push(nameToColor(element).hex));
        });

        tmp.map(function(element){
          tmp2.push({
           val: (colorDiff2(white, hexToRgb(element))),
           hex: element
          })
        });
        tmp2.sort((a, b) => a.val - b.val)
        tmp2.map((e=>tmp3.push(e.hex)))

        setHexColors(tmp3.join(', '));

    }

    useEffect(() => {
      genDataFromSave();
    }, [gameState])
    

  return hexColor ? (
    <div
    style={
      {
        background: `linear-gradient(to right, ${hexColor})`,
        width: '300px',
        height: '100px',
        border: '1px white solid'
      }
    }
    
    >
      
    </div>
  ) : ''
}

export default UniqueColorGraph