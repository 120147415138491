
import { useEffect, useRef, useState } from 'react';
import './App.css';
import namedColors from 'color-name-list';
import ColorDisplay from './ColorDisplay';
import InfoBar from './InfoBar';
import space from 'color-space';
import DeltaE from 'delta-e';
import {Buffer} from 'buffer';
import moment from 'moment';
import UniqueColorGraph from './UniqueColorGraph';
import { AiOutlineTwitter } from 'react-icons/ai';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import ShareScore from './ShareScore';
import vigeneren from 'vigeneren';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyBf8U-_Rzqd3Pw0zskvy360gLG9dd2Rtr0",
  authDomain: "colordle.firebaseapp.com",
  projectId: "colordle",
  storageBucket: "colordle.appspot.com",
  messagingSenderId: "393771697747",
  appId: "1:393771697747:web:0203df9059930734a3e8a6",
  measurementId: "G-ZT7WNX55T5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const dayOffset = 500

//2022 2 24 <--KEEP!!
//2022 8 24 <--KEEP NEW!!
//2022 8 24 <--KEEP NEW!! 2
let startDay = moment([2023, 7, 7]) 
let today = moment([])
const nameToColor = (name) => {
  return namedColors.find(color => color.name.toLowerCase().replace(/ /g,'') === name.toLowerCase().replace(/ /g,''));
}
function App() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [dayNum, setDayNum] = useState(today.diff(startDay, 'days')+dayOffset);
  const [easyModeEnabled, setEasyModeEnabled] = useState(false)
  const [tColorName, setTColorName] = useState('');
  const [lastColor, setLastColorName] = useState('');
  const getData=()=>{
    fetch('./colors.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        return response.json();
      })
      .then(function(myJson) {
        setTColorName(myJson.colors[dayNum - dayOffset]);
        try {
        setLastColorName(myJson.colors[dayNum - dayOffset-1]);
        } catch {

        }
      });
  }
  const [gameState, setGameState] = useState( {
    selectedColors: [
      {
      color: {
        name: 'Guess!',
        hex: '#808080',
      },
      number: 0,
      val: -1
    }
  ],
    won: false,
    day: dayNum,
    streak: 0,
    firstTime: true,
    uniqueColorsGuessed: []
  });
  useEffect(() => {
    getData();
    let localData = localStorage.getItem('colordle-state');
    if(localData && !params.get("c")) {
    let jsonC = JSON.parse(Buffer.from(localData, 'base64').toString('ascii'));
    setGameState(jsonC)
    if(dayNum!==jsonC.day) {
      setGameState({selectedColors: [
        {
        color: {
          name: 'Guess!',
          hex: '#808080',
        },
        number: 0,
        val: -1
      }
    ],
      won: false,
      day: dayNum,
      firstTime: false,
      streak: jsonC.won ? jsonC.streak : 0,
      uniqueColorsGuessed: jsonC.uniqueColorsGuessed || []
    })  
    }
    }
    
    
  }, [])
  

  const [colorInput, setColorInput] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [validColor, setValidColor] = useState();
  const [showHint, setShowHint] = useState(false);
  
  let colorData = [];
  
  
  const inputRef = useRef();
  const targetColor = nameToColor(params.get("c") ? vigeneren.decode(params.get("c"), "q2wedrfghjklkjnb") : tColorName);
  const onInputChange = (e) => { 
    const input = e.target.value;
    setColorInput(input);
    if(input.length<3) {
      setIsValid(false)
      return;
    }
    const foundColor = nameToColor(input);
    if(foundColor) {
      setIsValid(true)
      setValidColor(foundColor);
    } else setIsValid(false)
  }
  const saveGame = () => {
    // setCookie('colordle', new Buffer(JSON.stringify(gameState)).toString('base64'));
    if(!params.get("c")) {
      localStorage.setItem('colordle-state', new Buffer(JSON.stringify(gameState)).toString('base64'));
    }
  }
  
  // const colorDiff = (c1, c2) => { return  Math.sqrt((c1.r - c2.r) * (c1.r - c2.r) 
  //                              + (c1.g - c2.g) * (c1.g - c2.g)
  //                              + (c1.b - c2.b) * (c1.b - c2.b)); }

  const colorDiff = (c1, c2) => {
    let color1 = space.rgb.lab([c1.r, c1.g, c1.b]);
    let color2 = space.rgb.lab([c2.r, c2.g, c2.b]);
    let color1LAB = {L: color1[0], A: color1[1], B: color1[2]};
    let color2LAB = {L: color2[0], A: color2[1], B: color2[2]};
    return Math.abs(100 - DeltaE.getDeltaE00(color1LAB, color2LAB));
  }
 
  function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
const onColorSubmit = () => {
  var savedColors = gameState;
  let eM = {
    red: hexToRgb(targetColor.hex).r - hexToRgb(validColor.hex).r,
    green: hexToRgb(targetColor.hex).g - hexToRgb(validColor.hex).g,
    blue: hexToRgb(targetColor.hex).b - hexToRgb(validColor.hex).b,
  }
  let hint = []

    if(Math.abs(eM.red) > 30 ) {
      if(eM.red < 0) {
        hint.push("LESS RED")
      }
      else {
        hint.push("MORE RED")
      }
    }
    if(Math.abs(eM.green) > 30 ) {
      if(eM.green < 0) {
        hint.push("LESS GREEN")
      }
      else {
        hint.push("MORE GREEN")
      }
    }
    if(Math.abs(eM.blue) > 30 ) {
      if(eM.blue < 0) {
        hint.push("LESS BLUE")
      }
      else {
        hint.push("MORE BLUE")
      }
    }

  savedColors.selectedColors.push({
    color: validColor,
    number: (savedColors.selectedColors[savedColors.selectedColors.length-1].number + 1),
    val: colorDiff(hexToRgb(targetColor.hex), hexToRgb(validColor.hex)),
    eMH: hint.join(" | ")
    // val: colorDiff((targetColor.hex), (validColor.hex))
  })
  // fetch('http://localhost:6940/' + validColor.name.toLowerCase().replace(/ /g,''), {method: 'post'});
  colorData.push(validColor.name);
  if(!savedColors.uniqueColorsGuessed.includes(validColor.name.toLowerCase().replace(/ /g,''))) {
    savedColors.uniqueColorsGuessed.push(validColor.name.toLowerCase().replace(/ /g,''));
  }
  if(validColor.hex === targetColor.hex) {
    savedColors.won = true;
    logEvent(analytics, "daily_win");
    savedColors.streak++;
  }
  savedColors.firstTime = false;
  setGameState(savedColors);
  inputRef.current.focus();
  console.log(validColor.name)
  logEvent(analytics, "color_guess", {
    color: validColor.name 
  });
  saveGame();
  setColorInput('');
  setIsValid(false)


}
const handleKeyPress = (e) => {
  if(e.key === 'Enter' && isValid) onColorSubmit();
}
  return gameState.selectedColors ? (
    <>
    <center >
      {/* <button onClick={()=>setEasyModeEnabled(!easyModeEnabled)}style={{}}>{easyModeEnabled ? "Switch to Normal Mode": "Switch to Easy Mode"}</button> */}
      <h2>{params.get("c") ? "FRIEND CHALLENGE MODE" : "" }</h2>
    </center>
    <InfoBar lastColor={lastColor} gameState={gameState} nameToColor={nameToColor} hexToRgb={hexToRgb} colorDiff={colorDiff} />
    {/* <center id='ad'><div data-mndbanid="667a6435-9c53-4b92-a37b-96d6db31a32e"></div></center> */}
    <div className="App">

      {tColorName!=='' && (<div>
        <h1>Colordle</h1>
        <h2>Day {dayNum}</h2>
        <h3>The closer you are to the mystery color, the higher the percent!</h3>
        <center>
        <div className='color-shower' style={{
          width: '100px',
          height: '100px',
          background: gameState.won ? targetColor.hex : isValid ? validColor.hex : 'transparent',
        }}>{gameState.won ? '' : isValid ? '' : '?'}</div>
        </center>
        
        <input type='text' style={
          {
            display: gameState.won ? 'none' : 'inline'
          }
        } placeholder='Guess your color here'
         autoFocus 
         value={colorInput} 
         onChange={(e)=>onInputChange(e)}
         className='color-input'
         ref={inputRef}
         onKeyDown={(e)=>handleKeyPress(e)}></input>
        <button className={`color-input ${isValid ? '' : 'disabled'}`} 
        style={
          {
            display: gameState.won ? 'none' : 'inline'
          } }
          disabled={!isValid} onClick={()=>onColorSubmit()}>Test Color!</button>
          
        <center className='colorlist'>
        <span style={
          {
            display: (dayNum!==-1 || gameState.won)  ?  'none' : 'inline',
          } }><button onClick={()=>{
            if(showHint || window.confirm("Are you sure you want to view todays hint?")) setShowHint(!showHint)
          }} className='hint-btn'>Click for Hint</button>
           <div style={{
              display: showHint  ?  'inline' : 'none'
            }}> It's edible (food)!</div></span>
        <h1 style={
          {
            display: gameState.won ? 'block' : 'none'
          }} >You guessed the right color, {targetColor.name}, in {gameState.selectedColors[gameState.selectedColors.length-1].number || 0}
        
           {gameState.selectedColors[gameState.selectedColors.length-1].number  === 1 ? ' guess' : ' guesses'}! 
           <ShareScore 
            dayNum={dayNum}
            gameState={gameState}
          />
           {/* <a href={`
           https://twitter.com/intent/tweet?text=Colordle%20day%20${dayNum}%20%20%0AI%20got%20it%20in%20${gameState.selectedColors[gameState.selectedColors.length-1].number}%20guesses!&url=https://colordle.ryantanen.com
           `}><button className='icon'><AiOutlineTwitter fontSize={'2em'} /></button></a> */}
           </h1>
  
          
         {gameState.selectedColors.length > 1 && (<div style={{borderBottom: '3px white solid'}}><ColorDisplay color={gameState.selectedColors[gameState.selectedColors.length-1].color} index={gameState.selectedColors[gameState.selectedColors.length-1].number} val={gameState.selectedColors[gameState.selectedColors.length-1].val} eMH={easyModeEnabled ? gameState.selectedColors[gameState.selectedColors.length-1].eMH : ""} /></div>)}
        {gameState.selectedColors.slice().sort((a, b)=>parseFloat(a.val) - parseFloat(b.val)).reverse().map((element, i) => {
          return element.number !== gameState.selectedColors[gameState.selectedColors.length-1].number
         && (
            <span key={i}>
            
          <ColorDisplay color={element.color} index={element.number} val={element.val} eMH={easyModeEnabled ? element.eMH : ""} /></span>
          );
        })}
        </center>
      </div>)}
    </div>
    </>
  ) : "";
}

export default App;
