import React from 'react'
import space from 'color-space';
function ColorDisplay(props) {
    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }
      
    const color = space.lab.rgb(space.rgb.lab([hexToRgb(props.color.hex).r, hexToRgb(props.color.hex).g, hexToRgb(props.color.hex).b]));

    return (
  
    <div className='selected-color-contianer' style={{ 
        // background: `rgb(${color[0].toFixed(2)}, ${color[1].toFixed(2)}, ${color[2].toFixed(2)})`
        background: props.color.hex
        }}>
        <h1>#{props.index} {props.color.name} {props.color.name !== 'Guess!' ? '- ' + props.val.toFixed(2) + '%' :  ''}</h1> {props.eMH == -1  || !props.eMH ? '': <><br /> <h2> {props.eMH}</h2></>}
        
        
    </div>
  )
}

export default ColorDisplay